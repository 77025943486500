<template>
    <div  class="modal-backdrop" @click.self="$emit('close-modal')">
      <div class="modal-content"  >
        <span class="close" @click="$emit('close-modal')">&times;</span>
        <h2 v-if="internalAbilityData && internalAbilityData.name" :style="{'background-color': color.value}">{{internalAbilityData.name.toUpperCase()}}</h2>
        <p  v-if="internalAbilityData && internalAbilityData.effectEntries[1]" style="text-shadow: 0 3px 5px black;">{{internalAbilityData.effectEntries[1]}}</p>

        <div class="container" v-if="theme">
        <h3 style="color:red">Disclaimer</h3>
                <p style="font-size:large">This is an unofficial, fan-made app and is NOT affiliated, endorsed or supported by Nintendo, Game Freak and The Pokémon Company in any way. </p>
            <div></div>
        <h3 style="color:red">Special Thanks</h3>
                <p style="font-size:large;"> Thanks to PokeAPI & Veekun for the data, Bulbapedia for the images and of course to Nintendo, Game Freak, and The Pokémon Company for making such an awesome series of games.</p>
    </div>
      </div>
     
    </div>
  </template>
  
  <script>
  export default {
    props: ['abilityDataComputed', 'color', 'theme'],
    data() {
      return {
        internalAbilityData: null,
      };
    },
    watch: {
      abilityDataComputed: {
        immediate: true,
        handler(val) {
          this.internalAbilityData = val;
        },
      },
    },
   computed: {
    console: () => console
   },
 
  };
  </script>
  <style>
  .modal-content {
      width: 400px;
      padding: 20px;
      margin: 100px auto;
     background-color: gray;
      border-radius: 10px;
  }

  .modal-backdrop {
position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
  }

  h2, p {
    position: center;
  }

  .close {
  margin: 10% 0 0 16px;
  cursor: pointer;
}

  .modal h1{
      color: aqua;
      border: none;
  }

  .modal.sale {
      background: crimson;
      color: white;
  }
  .modal.sale h1 {
      color: white;
  }
</style>