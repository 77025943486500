<template>
  <nav class="nav-bar">
    <ul>
      <li><router-link :to="{name: 'home'}" class="main-page">PokeDex</router-link></li>
      <br>
      <li @click="showModal = true">Disclaimer</li>  
    </ul>
  </nav>
  <router-view/>
  <div v-if="showModal">
        <Modal :theme=theme @close-modal="showModal = false"></Modal>
        </div>
</template>
 
<script>
import Modal from './components/Modal.vue';

    export default {
      components: {
          Modal
      },
        data() {
            return {
              showModal: false,
              theme: 'disclaimer'
            }
        },
        methods: {
    
  },
    }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.main-page {
  text-decoration: none;
}

ul {
    display: block;
    list-style-type: none;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.nav-bar {
    height: 12%;
    display: block;
    align-items: center;
    text-align: right;
}

nav h2{
  padding: 30px;
  flex: 1;
  text-align: right;
}

nav li {
  font-weight: bold;
  color: white;
  text-decoration: none;
}

nav ul li {
  list-style: none;
  display: inline-block;
  margin-left: 60px;
}

nav li:hover {
  color: #335CFF;
  cursor: pointer;
}

nav a.router-link-exact-active {
  color: #335CFF;
}
</style>