import { createRouter, createWebHistory } from 'vue-router'
import Pokedex from '../views/Pokedex.vue'
import Details from '../views/Details.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: Pokedex
  },

  {
    path: '/details/:name',
    name: 'details',
    component: Details,
    props: (route) => ({name: route.params.name})
  },

 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
