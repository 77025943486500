import { ref } from "vue"


const getPokemon = (url) => {
    const pokedex = ref([])
    const error = ref(null)
   

    const load = async () => {
        try {
            let data = await fetch(url)
            if(!data.ok) {
                throw Error('no data available')
            }
            pokedex.value = await data.json()
        } catch (err) {
            error.value = err.message
            console.log(error.value)
            console.log(url)
        }
    }

    async function pokemonDetails(pokeURL) {
        const res = await fetch(pokeURL)
        const data = await res.json()
        const pokeNum = data.id
        return pokeNum
    }

    async function getAbility(abilityUrl) {
        const response = await fetch(abilityUrl);
        const data = await response.json();
        const effectEntries = data.effect_entries.map(entry => entry.effect);
        return { name: data.name, effectEntries };
      }
      
    
    return {pokedex, error, load, getAbility, pokemonDetails}
}



export default getPokemon