<template>
    <div class="container-grid">
        <div class="felx-child stats">
            <h1 :style="{ backgroundColor: color.value }">Stats:</h1>
            <table class="stat-table" :style="{borderColor: color.value}">
                <tr v-for="(stat, index) in pokemon.stats" :key="index">
                    <td>
                        <div class="stat-name" :style="{ color: color.value, textShadow: `0 3px 5px black` }">{{ capFirstLetter(stat.stat.name) }}:
                        </div>
                        <div class="stat-number"> {{ stat.base_stat }}</div>
                        <div class="stat-bar">
                            <div class="outer-bar" :style="{ backgroundColor: `rgba(${color.value}, 0.5)` }">
                                <div class="inner-bar"
                                    :style="{ width: updateStatBars(stat.base_stat), backgroundColor: color.value }">
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>

        <div class="flex-child details">
            <h1 :style="{ backgroundColor: color.value }">{{ pokemon.name.toUpperCase() }}</h1>
            <h2>#{{ pokemon.id }}</h2>
            <img :src="pokemon.sprites.other['official-artwork'].front_default" alt=""
                :style="`filter: drop-shadow(0 5px 3px white); background-color: ${color.value}; border-radius: 200pt;`" />
            <div v-for="(type, index) in pokemon.types" :key="index" class="typeIco-container">
                <img :src="`${publicPath}pokemon-types/${type.type.name}.ico`" :alt="type.type.name" class="typeIco" />
            </div>
            <div :style="{ backgroundColor: color.value }" class="pill" v-for="(ability, index) in pokemon.abilities"
                :key="index">
                <span @click="handleClick(ability.ability.url)" ><p style="text-shadow: 0 3px 5px black;">{{ ability.ability.name.toUpperCase() }}</p></span>
            </div>
        </div>

        <div v-if="multipliers" class="flex-child weak">
            <h1 :style="{ backgroundColor: color.value }">Weaknesses:</h1>
            <table class="weak-table" :style="{borderColor: color.value}">
                <tr v-for="(value, type) in multipliers.defense" :key="type">
                    <td>
                        <img :src="`${publicPath}pokemon-types/${type}.ico`" alt="" class="weakIco">:
                    </td>
                    <td class="weak-value">
                        {{ value }}
                    </td>
                </tr>
            </table>
        </div>
        <Modal v-if="showModal" :abilityDataComputed=abilityDataComputed :color=color @close-modal="showModal = false">
        </Modal>
    </div>
</template>
  
<script>
import { ref, watch, computed } from "vue";
import getPokemon from "../../composables/getPokemon";
import getMultipliers from "../../composables/getMultipliers"
import Modal from '../components/Modal.vue'
import typeColors from "../../composables/typeColors";


export default {
    emits: ['color-update'],
    props: ['name'],
    components: {
        Modal
    },
    watch: {
        color: function (newColor) {
            this.$emit('color-update', newColor)
        }
    },
    data: function () {
        return {
            publicPath: process.env.BASE_URL,

        }
    },

    setup(props) {
        const pokemon = ref(null);
        const abilityData = ref(null)
        const showModal = ref(false)
        const url = `https://pokeapi.co/api/v2/pokemon/${props.name}`
        const { pokedex, error, load, getAbility } = getPokemon(url);

        load();




        watch(() => props.name, (newName) => {
            const newUrl = `https://pokeapi.co/api/v2/pokemon/${newName}`;
            load(newUrl);
        });

        //get type color
        const { getTypeColor } = typeColors();
        const color = ref("");
        watch(pokedex, (newPokedex) => {
            if (newPokedex) {
                pokemon.value = newPokedex;
                if (pokemon.value) {
                    const typeName = pokemon.value.types[0].type.name;
                    const { color: typeColor } = getTypeColor(typeName);
                    color.value = typeColor;
                }
            }
        });

        // compute the multipliers based on the pokemon object
        const multipliers = computed(() => {
            if (!pokemon.value) return null;
            return getMultipliers(pokemon.value.types);
        });

        //cap first letter
        function capFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        }


        //get Ability data for modal
        const getAbilityData = async (url) => {
            try {
                const data = await getAbility(url);
                abilityData.value = data;
            } catch (error) {
                console.error(error);
            }
        };

        const abilityDataComputed = computed(() => {
            return abilityData.value;
        });

        function handleClick(abilityURL) {
            getAbilityData(abilityURL);
            showModal.value = true
        }

        //update stat bars
        const updateStatBars = (stat) => {
            const barInner = `${stat}%`
            return barInner
        }

        return { pokemon, multipliers, capFirstLetter, color, showModal, handleClick, abilityDataComputed, updateStatBars };
    },

};

</script>

  
<style>
.container-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
}



.pill {
    display: block;
    margin: 40px 10px 0 0;
    padding: 6px 12px;
    background: #eee;
    border-radius: 20px;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: bold;
    color: #777;
    cursor: pointer;
    filter: drop-shadow(0 5px 3px black);
    transition: transform 200ms;
    
}


.pill:hover {
    background-color: white;
    color: black;
}

.weak-table{
    width: 100%;
    justify-content: center;
    table-layout: auto;
}

.weak-table td {
    border-style: solid;
    border-radius: 15px;
    border-color: black;
}

.weak-value, .stat-number {
    color: white;
    
}

.stat-table,
.weak-table {
    background-color: rgba(255,255,255,0.5);
    border-radius: 15px;
    color: white;
    border-style: ridge;
    overflow: auto;
}

.stat-table:hover,
.weak-table:hover {
    background-color: rgba(255,255,255,1);
    border-radius: 15px;
    border-style: outset;
    
}

.stat-table:hover .stat-number ,
.weak-table:hover .weak-value {
    color: black;
    text-shadow: none;
}

.stat-table {
    width: 100%;
}


.stat-bar {
    width: 76%;
    display: flex;
    align-items: center;
    padding-left: 0.6rem;
}

.outer-bar {
    background-color: rgba(116, 203, 72, 0.3);
    display: flex;
    width: 100%;
    height: 0.3rem;
    border-radius: 4px;
    transition: 0.5s ease-in-out;
}

.inner-bar {
    background-color: rgb(116, 203, 72);
    border-radius: 4px;
    transition: 0.5s ease-in-out;
}

.typeIco-container {
    display: inline-block;
}

.typeIco {
    height: 80px;
    width: 80px;
    margin: 5%;
    align-items: center;
    filter: drop-shadow(0 5px 3px white);
    transition: transform 200ms;
}

.weakIco {
    height: 35px;
    width: 35px;
    margin: 5%;
    filter: drop-shadow(0 5px 3px black);
    transition: transform 200ms;
    align-items: center;
}




.weakIco:hover {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow:
        inset 0 0 60px 30px #fff,
        /* inner white */
        /* outer cyan */
}
</style>
  