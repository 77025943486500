<template>
  <div class="home">
    <div v-if="error">{{ error }}</div>
    <input type="text" v-model="search" class="search" placeholder="Search Pokemon by Name">
    <div v-if="pokemon" class="pokemon">
      <div v-for="(poke, index) in matchingNames" :key="index">
        <div class="pokeCard">
        <router-link :to="{ name: 'details', params: { name: poke.name } }">
          <h2>{{ capFirstLetter(poke.name) }}</h2>
          <div class="img-container">
            <img :src="poke.artwork" alt="Official Art" class="img-size">
          </div>
        </router-link>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref,watch } from "vue";
import getPokemon from "../../composables/getPokemon";
import { computed } from "@vue/reactivity";
import Details from './Details.vue'

export default {
  components: { Details },
  data: function () {
        return {
            publicPath: process.env.BASE_URL,
            color: '',
        }
    },
    methods: {
      handleColorUpdate(newColor) {
        this.color = newColor
      }
    },
  setup() {
    const search = ref('')
    const limit = 1010
    const offset = ref(0)
    let url = `https://pokeapi.co/api/v2/pokemon?limit=${limit}&offset=${offset.value}`;
    const { pokedex, error, load, pokemonDetails } = getPokemon(url);
    const pokemon = ref(null);
    const pokeId = ref(null)


    load();
    setTimeout(() => {
      pokemon.value = pokedex.value.results;

    }, 1000);

    const matchingNames = computed(() => {
    const filteredPokemon = pokemon.value?.filter((p) =>
    p.name.toLowerCase().includes(search.value.toLowerCase())
  );

  // Add the artwork property to each matching object
  return filteredPokemon?.map(p => ({ ...p, artwork: require(`../../src/assets/sprites/pokemon/other/official-artwork/${p.name}.png`) })) || [];
});

//change card background color
function changeColor(element) {
			var randomColor = Math.floor(Math.random()*16777215).toString(16);
			element.style.backgroundColor = "#" + randomColor;
		}



    function capFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return { pokemon, error, search, matchingNames, capFirstLetter, pokemonDetails, changeColor };
  },
};
</script>

<style scoped>

.search {
    border-radius: 8px 8px 8px 8px;
    border: 0;
    outline: none;
    height: 36px;
    padding: 4px 16px;
    background-color: white;
    color: #000;
}

.pokemon {
  display: flex;
  flex-wrap: wrap;
  align-items: space-between;
  justify-content: center;
  margin: 0 auto;
  max-width: 1200px;
}
.pokeCard h2 {
  text-shadow: 0 0 5px black;
  font-size: 15px;
}

.pokeCard h2:hover {
  color: #335CFF;
}

.pokeCard {
  width: 160px;
  height: 190px;
  display: inline-block;
  border-radius: 10px;
  padding: 15px 25px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 10px 15px;
  align-items: center;
  background-color: transparent;
  border-top:  1px solid white;
  border-bottom:  1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  transition: transform 0.5s;
  text-align: center;
}

.pokeCard:hover {
    transform: translateY(-10px);
    box-shadow: 0px 1px 2px black;
    background-color: gray;
}

.img-size {
  max-width:90%;
  filter: drop-shadow(0 5px 3px black)
}

.img-container {
  background-color: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    width: 120px;
    height: 120px;
    text-align: center;
}
a {
  color: black;
  text-decoration: none;
}

</style>