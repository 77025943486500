import allTypes from '../src/assets/all_types.json'

function getMultipliers(types) {
  var multipliers = {
    defense: {},
    attack: {}
  }

  types.forEach((type) => {
    const typeName = type.type.name
        
    if (Object.keys(allTypes).includes(typeName)) {
        var damage_relations = allTypes[typeName]
        // rest of code
        if (allTypes.hasOwnProperty(typeName)) {
            // rest of your code for calculating multipliers
        var no_damage_to = damage_relations.attack.zero
        var no_damage_from = damage_relations.defense.zero
        var half_damage_to = damage_relations.attack.half
        var half_damage_from = damage_relations.defense.half
        var double_damage_to = damage_relations.attack.double
        var double_damage_from = damage_relations.defense.double
        no_damage_to.forEach((typeName) => {
          if (multipliers.attack.hasOwnProperty(typeName)) {
            multipliers.attack[typeName] = multipliers.attack[typeName] * 0
          } else {
            multipliers.attack[typeName] = 0
          }
        })
        no_damage_from.forEach((typeName) => {
          if (multipliers.defense.hasOwnProperty(typeName)) {
            multipliers.defense[typeName] = multipliers.defense[typeName] * 0
          } else {
            multipliers.defense[typeName] = 0
          }
        })
        half_damage_to.forEach((typeName) => {
          if (multipliers.attack.hasOwnProperty(typeName)) {
            multipliers.attack[typeName] = multipliers.attack[typeName] * 0.5
          } else {
            multipliers.attack[typeName] = 0.5
          }
        })
        half_damage_from.forEach((typeName) => {
          if (multipliers.defense.hasOwnProperty(typeName)) {
            multipliers.defense[typeName] = multipliers.defense[typeName] * 0.5
          } else {
            multipliers.defense[typeName] = 0.5
          }
        })
        double_damage_to.forEach((typeName) => {
          if (multipliers.attack.hasOwnProperty(typeName)) {
            multipliers.attack[typeName] = multipliers.attack[typeName] * 2
          } else {
            multipliers.attack[typeName] = 2
          }
        })
        double_damage_from.forEach((typeName) => {
          if (multipliers.defense.hasOwnProperty(typeName)) {
            multipliers.defense[typeName] = multipliers.defense[typeName] * 2
          } else {
            multipliers.defense[typeName] = 2
          }
        })
          }
    } else {
        console.log(`Type '${type}' not found in allTypes object`);
    }
    
    
  })

  return multipliers
}

export default getMultipliers
